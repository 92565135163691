
<template>
<div id="profile-page">

  <div class="vx-row">
  
    <div class="vx-col w-full lg:w-1/2">
      <vx-card class="mb-5" v-for="(post, index) in postList" :key="index" @click.stop="toRead(post)">
        <div>
          
          <div class="post-header mb-4">
              <div class="ml-4">
                <h3>{{ post.task_title }}</h3>
              </div>
              <div class="flex justify-end items-center">
                <div class="ml-4">
                  <p>{{ post.tanggal }}</p>
                </div>
              </div>
          </div>
          <div class="post-media-container mb-6 mt-4">
            <ul class="flex post-media-list">
              <img class="responsive rounded" :src="getImage('/post/'+post.thumbnail)" :alt="post.task_title">
            </ul>
          </div>
          <div class="post-content">
            <p v-html="post.content.substring(0, 190)"></p>
          </div>

        </div>
      </vx-card>
    </div>
      
  </div>

  <div class="vx-row mb-12 pb-base">
      <div class="vx-col w-full">
          <div class="flex justify-center">
              <vs-button id="button-load-more-posts" class="vs-con-loading__container" @click="loadContent" :disabled="disabled">Load More</vs-button>
          </div>
      </div>
  </div>
</div>
</template>

<script>
  import modulePost from '@/stores/post/modulePost.js'

  export default {
    data () {
      return {
        search: '',
        mulai: null,
        sampai: null,
        page: 1,
        disabled: false
      }
    },
    computed: {
      postList () { 
        return this.$store.state.post.posts 
      },
      postTotal () {
        return this.$store.state.post.total
      },
      postTotalSatu () {
        return this.$store.state.post.total + 1
      },
      postLast () {
        return this.$store.state.post.last
      },
      postPerPage () {
        return this.$store.state.post.per_page
      },
      postCurrentPage () {
        return this.$store.state.post.current_page
      },
      postPage () {
        const page = []
        const number = this.$store.state.post.total / this.$store.state.post.per_page
        var i
        for (i=0; i < number; i++) {
          page.push(i+1)
        }
        return page
      },
      postTotalPage () {
        let number = this.$store.state.post.total / this.$store.state.post.per_page
        if (number < 1) {
          number = 1
        }
        return number.toFixed(0)
      }
    },
    methods: {
      loadContent () {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: '#button-load-more-posts',
          scale: 0.45
        })
        this.page = this.page+1
        const obj = {
          type: 'post',
          filter_type: 'post_kategori',
          filter: this.$route.params.kategori,
          urutan: 'desc',
          jumlah: 5,
          search: this.search,
          dateMulai: this.mulai,
          dateSampai: this.sampai,
          page: this.page
        }
        this.$store.dispatch('post/loadPost', obj)
        .then((response) => {
          this.$vs.loading.close()
          if (response.data.data.tasks.length === 0) {
            this.$vs.notify({
              color  : 'warning',
              text  : 'Data sudah tidak ada',
            })
            this.disabled = true
          }
        })
        .catch(error => {
          this.$vs.loading.close()
          let {data} = error.response
          this.$vs.notify({
            title: 'Error',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })

        setTimeout(() => {
          this.$vs.loading.close('#button-load-more-posts > .con-vs-loading')
        }, 2000)
      },
      toRead (post) {
        const kategori = this.$route.params.kategori
        this.$router.push({
          name: "post-read",
          params: { post, kategori }
        });
      }
    },
    components: {
    },
    mounted () {
    },
    created () {
      this.$store.commit('navbarUpdateStatus', false)
      this.$vs.loading()
      this.$store.registerModule('post', modulePost)

      const obj = {
        type: 'post',
        filter_type: 'post_kategori',
        filter: this.$route.params.kategori,
        urutan:'desc',
        jumlah: 5
      }
      
      this.$store.dispatch('post/fetchPost', obj)
      .then((response) => {
        this.$vs.loading.close()
        if (response.data.data.tasks.length === 0) {
          this.$vs.notify({
            color  : 'warning',
            text  : 'Data belum ada',
          })
          this.disabled = true
        }
      })
      
      setTimeout(() => {
        this.$vs.loading.close()
      }, 4000)
    },
    beforeUpdate () {
      this.currFilter = this.$route.params.filter
    },
    beforeDestroy () {
      this.$store.unregisterModule('post')
    },
  }

</script>


<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
