
export default {
  SET_NULL_TASKS (state) {
    state.posts = []
  },

  // TASK
    SET_POSTS (state, posts) {
      state.posts = posts
    },
    PUSH_POSTS (state, posts) {
      posts.forEach(function(value) { 
        state.posts.push(value)
      });
      //state.posts.concat(posts)
    },
    SET_TOTALS (state, payload) {
      state.total = payload
    },
    SET_LAST (state, payload) {
      state.last = payload
    },
    SET_PER_PAGE (state, payload) {
      state.per_page = payload
    },
    SET_CURRENT_PAGE (state, payload) {
      state.current_page = payload
    },
    

}
