
import axios from '@/axios.js'

export default {
  
  fetchPost ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/task-list-page', payload)
        .then((response) => {
          commit('SET_POSTS', response.data.data.tasks)
          commit('SET_TOTALS', response.data.data.total)
          commit('SET_LAST', response.data.data.last_page)
          commit('SET_PER_PAGE', response.data.data.per_page)
          commit('SET_CURRENT_PAGE', response.data.data.current_page)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
  loadPost ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/task-list-page', payload)
        .then((response) => {
          commit('PUSH_POSTS', response.data.data.tasks)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

}
